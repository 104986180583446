import React, {useState, useEffect} from "react";
import Leaves from "../leaves";
import "./gate.css";
import Jarki from "../../Images/gate/jarki.png"
import Spotify from "../../Images/gate/spotify.png"
import Youtube from "../../Images/gate/youtube.png"

const links = {
    Jarki: {
        youtube: "https://www.youtube.com/watch?v=3t_pnUNvQ7w&ab_channel=Nuotta",
        spotify: "https://open.spotify.com/track/0Jk3dcFDmdQiaeoScywXs7?si=1d41ff22a6764274&nd=1"
    }
}

function Gate(props) {
    const {song} = props;

    function clickLink(target) {
        const url = links[song][target];
        window.open(url, '_blank').focus();
    }

    return <div>
        <div>
            <img className="blur" src={Jarki} alt=""/>
            <div className="content">
                <img className="albumCover" src={Jarki} alt="Nuotta levynkansi"/>
                <table className="platforms">
                    <tbody>
                        <tr onClick={() => clickLink("spotify")}>
                            <td><img src={Spotify} alt="Spotify"/></td>
                            <td><div className="listenBtn">Kuuntele Spotifyssä</div></td>
                        </tr>
                        <tr onClick={() => clickLink("youtube")}>
                            <td><img src={Youtube} alt="Youtube"/></td>
                            <td><div className={"listenBtn"}>Kuuntele Youtubessa</div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>;
}

export default Gate;